import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
// import BookList from '@/components/BookList.vue'
// import BookDetail from "@/components/BookDetail.vue";

// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect:'/BookList'
        // component: BookList
    },
    {
        path:"/BookList",
        name:'BookList',
        // component: BookList
        component: () => import('@/components/BookList'),
        meta:{
            title:'book-view'
        }
    },
    {
        path: "/BookDetail",
        name:'BookDetail',
        // component: BookDetail
        component: () => import('@/components/BookDetail'),
        meta:{
            title:'book-view'
        }

    },
    {
        path:"/GradeList",
        name:'GradeList',
        // component: BookList
        component: () => import('@/components/GradeList'),
        props:true,
        meta:{
            title:'book-view'
        }
    },
    {
        path: "/GradeBooks",
        name:'GradeBooks',
        // component: BookDetail
        component: () => import('@/components/GradeBooks'),
        props:true,
        meta:{
            title:'book-view'
        }

    },
    {
        path: "/BookVideo",
        name:'BookVideo',
        // component: BookDetail
        component: () => import('@/components/BookVideo'),
        props:true,
        meta:{title:"诗歌"}
    },
    {
        path: "/Clock",
        name:'Clock',
        // component: BookDetail
        component: () => import('@/components/clock/Clock'),
        props:true,
        meta:{
            title:'智能语音时间管家电子说明书'
        }
    }
]

var router =  new VueRouter({
    routes : routes,
    mode : 'history'
})
router.beforeEach((to, form, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = '默认标题' //此处写默认的title
    }
    next()
})
export default router;
