import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from "./router.js"
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer);
Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
  el: '#app',
  render: h => h(App),
  router
})
